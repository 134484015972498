import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  {
    id: BaseStepIdEnum.individual_edit,
  },
  {
    id: 'additional_details',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'employment_status',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'unemployed',
            'parttime_employed',
            'fulltime_employed',
            'selfemployed',
            'government_official',
            'student',
            'retired',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'source_of_wealth',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'salary',
            'savings',
            'sale_of_property',
            'pension',
            'inheritance',
            'family_financial_support',
            'investments',
            'others',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'yearly_income',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'between_0_and_50000',
            'between_50000_and_100000',
            'between_100000_and_250000',
            'more_than_250000',
          ],
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'iban',
          type: FieldTypeEnum.iban,
          nested: 'banking_information',
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.individual,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
  {
    id: 'investment_intentions',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'expected_trading_volume',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'between_100000_and_250000',
            'between_250000_and_500000',
            'between_500000_and_1000000',
            'more_than_1000000',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'wallet_address',
          type: FieldTypeEnum.text,
          isEnabled: true,
          isRequired: false,
          hasHelper: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'primary_usage_intent',
          type: FieldTypeEnum.select,
          isEnabled: true,
          isRequired: true,
          options: [
            'saving_in_bitcoin',
            'transfer_of_funds',
            'online_payment',
            'decentralized_currency_preferency',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'qr_code',
    type: StepTypeEnum.text,
  },
];
